export function getHierarchicalFieldValue(row: any, fieldName: string) {
  return fieldName.split('.').reduce((r, fn) => r && r[fn], row);
  // if (fieldName) {
  //   const keys = fieldName.split('.');
  //   let tmpValue = row;
  //   for (let i = 0; i < keys.length; i++) {
  //     tmpValue = tmpValue ? tmpValue[keys[i]] : null;
  //     if (!tmpValue) {
  //       return null;
  //     }
  //   }
  //   return tmpValue;
  // }
  // return undefined;
}
