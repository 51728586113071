import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'tableOsType',
  standalone: true,
})
export class TableOsTypePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(value: unknown, ...args: unknown[]): SafeHtml {
    if (value === null) return '';
    if (value === 'default')
      return this.sanitizer.bypassSecurityTrustHtml(
        `<img style="height:1.5rem" src="assets/images/emulator.png"/> <span class="ms-1 text-truncate text-capitalize">Emulator</span>`
      );
    return this.sanitizer.bypassSecurityTrustHtml(
      `<img style="height:1.5rem" src="assets/images/${value}.png"/> <span class="ms-1 text-truncate text-capitalize">${value}</span>`
    );
  }
}
