<span class="badge text-bg-dark me-1" *ngFor="let row of inputArray">
  {{ getMainFieldValue(row) }}
</span>
<div
  *ngIf="inputArray.length > 1"
  role="button"
  class="show-array-btn"
  (click)="$event.stopPropagation()"
  [ngbPopover]="popContent"
  [ngbTooltip]="inputArray.length"
  container="body"
  popoverClass="datatable-device-array-popover"
>
  <i class="ri-share-box-fill"></i>
</div>

<ng-template #popTitle> {{ header | translate }} </ng-template>
<ng-template #popContent>
  <designage-data-table
    [data]="inputArray"
    [columns]="desColumns"
    [columnSelector]="false"
    [showMultiSelect]="false"
    [showFilter]="false"
    [showFooter]="false"
  ></designage-data-table>
</ng-template>
