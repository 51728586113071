import { Injectable, computed, signal } from '@angular/core';
import { localStorageKeys } from '@desquare/constants';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class SlidePanelService {
  private isPanelOpen = signal<boolean>(false);
  private slidePanelComponentId = signal<string>('');

  constructor(private localStorageService: LocalStorageService) {}

  setIsPanelOpen(value: boolean) {
    this.isPanelOpen.set(value);
    if (!this.isPanelOpen()) this.setPanelComponentId('');
  }

  setPanelComponentId(value: string) {
    this.slidePanelComponentId.set(value);
  }

  getIsPanelOpen() {
    return computed(() => this.isPanelOpen());
  }

  getPanelComponentId() {
    return computed(() => this.slidePanelComponentId());
  }

  getPanelSettingsFromLs(): [
    { panelContent: string; openWidth: number; fullscreen: boolean }
  ] {
    const ls = this.localStorageService.retrieve(
      localStorageKeys.SLIDING_PANEL
    );
    return ls;
  }
  getPanelSettings(
    panelContent: string,
    openWidth: number,
    fullscreen: boolean
  ): { panelContent: string; openWidth: number; fullscreen: boolean } {
    const slidingPanels: [
      { panelContent: string; openWidth: number; fullscreen: boolean }
    ] = this.localStorageService.retrieve(localStorageKeys.SLIDING_PANEL);
    if (slidingPanels && slidingPanels.length > 0) {
      const panel = slidingPanels.filter((p) => {
        return p.panelContent === panelContent;
      });

      if (panel.length) return panel[0];
      return { panelContent, openWidth, fullscreen };
    }
    return { panelContent, openWidth, fullscreen };
  }

  setPanelSettings(
    panelContentParam: string,
    openWidthParam: number,
    fullscreenParam: boolean
  ) {
    const newSettings = {
      panelContent: panelContentParam,
      openWidth: openWidthParam,
      fullscreen: fullscreenParam,
    };
    let slidingPanels = this.getPanelSettingsFromLs();
    let panel;

    if (slidingPanels && slidingPanels.length > 0) {
      panel = slidingPanels
        .map((p) => {
          return p.panelContent;
        })
        .indexOf(panelContentParam);

      if (panel >= 0) {
        slidingPanels[panel] = { ...newSettings };
      } else {
        slidingPanels.push({ ...newSettings });
      }
      slidingPanels[
        slidingPanels.findIndex(
          (el) => el.panelContent === newSettings.panelContent
        )
      ];

      this.localStorageService.store(
        localStorageKeys.SLIDING_PANEL,
        slidingPanels
      );
    } else {
      slidingPanels = [{ ...newSettings }];
      this.localStorageService.store(
        localStorageKeys.SLIDING_PANEL,
        slidingPanels
      );
    }
  }
}
