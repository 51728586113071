const DEFAULT_LAYOUT_CONTAINER_ID = 'default-layout-container';
const CUSTOM_LAYOUT_CONTAINER_ID = 'custom-layout-container';
const LOGO_CONTAINER_ID = 'logo-container';
const LOGO_ID = 'logo';
const IMAGE_1_ID = 'image-1';
const IMAGE_2_ID = 'image-2';
const HTML_RESOURCE = 'html-resource';
const DEBUG_ID = 'debug';
const DATA_LANDSCAPE_CLASS = 'data-landscape-class';
const DATA_PORTRAIT_CLASS = 'data-portrait-class';
const DATA_DYNAMIC_COLUMNS = 'data-dynamic-columns';
const DATA_MAX_DYNAMIC_COLUMNS = 'data-max-dynamic-columns';
const DATA_PAGE_SIZE_OPTIONS = [10, 25, 50];

export const domConstants = {
  DEFAULT_LAYOUT_CONTAINER_ID,
  CUSTOM_LAYOUT_CONTAINER_ID,
  LOGO_CONTAINER_ID,
  LOGO_ID,
  IMAGE_1_ID,
  IMAGE_2_ID,
  HTML_RESOURCE,
  DEBUG_ID,
  DATA_LANDSCAPE_CLASS,
  DATA_PORTRAIT_CLASS,
  DATA_DYNAMIC_COLUMNS,
  DATA_MAX_DYNAMIC_COLUMNS,
  DATA_PAGE_SIZE_OPTIONS,
};
