import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { DeviceData } from '@desquare/types';
import { DeviceDataService } from '@desquare/services';
import { Signal, computed, inject, signal } from '@angular/core';
import { distinctUntilChanged, pipe, switchMap, tap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import { DeviceStatusCode } from '@desquare/models';
import { TopicLogic } from 'libs/core/services/src/lib/device-data/mqtt.topic.logic';
import { LocalStorageService } from 'ngx-webstorage';

export interface IDeviceDataSignal {
  devices: DeviceData[];
  loading: boolean;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
  wtSelectedDevicesForDetailPanel: string[];
}

const initialState: IDeviceDataSignal = {
  devices: [],
  loading: true,
  errors: null,
  wtSelectedDevicesForDetailPanel: [],
};

export const DevicesStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods(
    (
      store,
      deviceDataService = inject(DeviceDataService),
      localStorageService = inject(LocalStorageService)
    ) => ({
      getDevicesFromApi: rxMethod<void>(
        pipe(
          distinctUntilChanged(),
          switchMap(() => {
            return deviceDataService.getDevicesFromApi$().pipe(
              tapResponse({
                next: ({ devices, errors, loading }) => {
                  patchState(store, {
                    devices,
                    loading,
                    errors: errors ? [...errors] : [],
                  });
                },
                error: console.error,
                finalize: () => patchState(store, { loading: false }),
              })
            );
          })
        )
      ),
      getMqttData: rxMethod<void>(
        pipe(
          switchMap(() => {
            return deviceDataService.getDeviceDataFromMqtt$().pipe(
              distinctUntilChanged(),
              tapResponse({
                next: (mqttMessage) => {
                  if (store.loading()) return;
                  if (mqttMessage.retain) return;

                  const topic = TopicLogic.getTopicInfo(mqttMessage.topic);
                  patchState(store, {
                    devices: store.devices().map((device) => {
                      if (device.id === topic.DeviceId) {
                        return deviceDataService.parseMqttDeviceData(
                          mqttMessage,
                          topic,
                          device
                        );
                      }
                      return device;
                    }),
                  });
                },
                error: console.error,
              })
            );
          })
        )
      ),
      getScreenshotUpdates: rxMethod<void>(
        pipe(
          switchMap(() => {
            return deviceDataService.subscribeToMqttScreenshots$().pipe(
              distinctUntilChanged(),
              tapResponse({
                next: (mqttMessage) => {
                  const topic = TopicLogic.getTopicInfo(mqttMessage.topic);
                  patchState(store, {
                    devices: store.devices().map((device) => {
                      if (device.id === topic.DeviceId) {
                        const bucketUrl =
                          'https://designage2-screenshots-staging.s3.eu-north-1.amazonaws.com';
                        const now = Math.floor(Date.now());
                        return {
                          ...device,
                          screenshotUrl: `${bucketUrl}/${topic.DeviceId.substring(
                            0,
                            3
                          )}/${topic.DeviceId}.jpeg?t=${now}`,
                          lastPing: new Date(),
                        };
                      }
                      return device;
                    }),
                  });
                },
                error: console.error,
              })
            );
          })
        )
      ),
      setSelectedDevicesForDetailPanel: (deviceId: string, clear?: boolean) => {
        if (clear) {
          localStorageService.clear('selectedForDetail');
          return patchState(store, {
            wtSelectedDevicesForDetailPanel: [],
          });
        }
        const currentSelected = [...store.wtSelectedDevicesForDetailPanel()];
        const isSelected = store
          .wtSelectedDevicesForDetailPanel()
          .findIndex((id) => id === deviceId);
        if (isSelected != -1) {
          currentSelected.splice(isSelected, 1);
        } else {
          currentSelected.push(deviceId);
        }
        patchState(store, {
          wtSelectedDevicesForDetailPanel: currentSelected,
        });
        localStorageService.store(
          'selectedForDetail',
          store.wtSelectedDevicesForDetailPanel()
        );
      },
      getDevice: (deviceId: string): Signal<DeviceData | undefined> => {
        const device = signal<DeviceData | undefined>(
          store.devices().find((x) => x.id === deviceId)
        );
        return device;
      },
      addDevice: (device: DeviceData) => {
        patchState(store, {
          devices: [...store.devices(), device],
        });
      },
      updateDeviceSettings: (device: DeviceData) => {
        console.log('updateDeviceSettings', device);

        patchState(store, {
          devices: store.devices().map((x) => {
            if (x.id === device.id) {
              return { ...x, ...device };
            } else {
              return x;
            }
          }),
        });
      },
    })
  ),
  withHooks((store) => ({
    onInit() {
      // Load products when the store is initialized
      store.getDevicesFromApi();
      store.getMqttData();
      store.getScreenshotUpdates();
    },
  })),
  withComputed((state) => ({
    offlineDevices: computed(() => {
      return state.devices().filter((x) => {
        if (x.status && x.status.Status)
          return x.status.Status === DeviceStatusCode.offline;
        return false;
      });
    }),
    onlineDevices: computed(() =>
      state.devices().filter((x) => {
        if (x.status && x.status.Status)
          return x.status.Status === DeviceStatusCode.online;
        return false;
      })
    ),
    wtDevicesForDetailPanel: computed(() =>
      state
        .devices()
        .filter((x) => state.wtSelectedDevicesForDetailPanel().includes(x.id))
    ),
  }))
);
