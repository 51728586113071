<span class="badge bg-dark me-1" *ngFor="let device of devicesArray">
  {{ device.name }}
</span>
<div
  *ngIf="devicesArray.length > 0"
  role="button"
  class="show-array-btn"
  (click)="$event.stopPropagation()"
  [ngbPopover]="popContent"
  container="body"
  popoverClass="datatable-device-array-popover"
>
  <i class="ri-share-box-fill"></i>
</div>

<ng-template #popTitle>
  {{ 'DEVICES_CONNECTED_TO_CHANNEL' | translate }}
</ng-template>
<ng-template #popContent>
  <designage-data-table
    [data]="devicesArray"
    [columns]="desColumns"
    [columnSelector]="false"
    [showMultiSelect]="false"
    [showFilter]="false"
    [showFooter]="false"
  ></designage-data-table>
</ng-template>
