import { computed, inject, input } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ChannelRow } from '@desquare/interfaces';
import { DeviceStatusInfo } from '@desquare/models';
import { DevicesStore } from '@desquare/stores';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Maybe } from 'graphql/jsutils/Maybe';

@Component({
  standalone: true,
  imports: [NgbTooltip, TranslateModule],
  selector: 'table-status-indicator',
  template: `
    @if(status()){
    <div
      class="status-indicator"
      [style.background-color]="status()?.Color"
      ngbTooltip="{{ status()?.StatusLabel | translate }}"
    ></div>
    } @if(channelRow() && device()){
    <div
      class="status-indicator"
      [style.background-color]="device()?.status?.Color"
      ngbTooltip="{{ device()?.status?.StatusLabel | translate }}"
    ></div>
    }
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableStatusIndicatorComponent {
  devicesStore = inject(DevicesStore);
  channelRow = input<ChannelRow>();
  status = input<Maybe<DeviceStatusInfo>>();

  device = computed(() => {
    const devices = this.channelRow()?.devices;
    if (!devices || !devices[0]?.id) return;
    return this.devicesStore.getDevice(devices[0].id)();
  });
}
