import { inject } from '@angular/core';
import {
  ChannelBulkAction,
  PlaylistBulkAction,
  MediaBulkAction,
  DeviceBulkAction,
  PublishChannelsContentGQL,
} from '@designage/gql';
import { ChannelRow } from '@desquare/interfaces';
import { CurrentUserService, DeviceDataService } from '@desquare/services';
import { DeleteChannelDialogComponent } from '@desquare/components/common/src/modals/delete-channel-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';

const buttonClasses: {
  [key: string]: (
    | DeviceBulkAction
    | ChannelBulkAction
    | PlaylistBulkAction
    | MediaBulkAction
  )[];
} = {
  'btn-outline-primary': [
    ChannelBulkAction.AddScreen,
    ChannelBulkAction.AppletReload,
    PlaylistBulkAction.DuplicateSelected,
    MediaBulkAction.MoveSelected,
  ],
  'btn-outline-light': [
    ChannelBulkAction.DisplayOn,
    DeviceBulkAction.DeviceDisplayOn,
  ],
  'btn-dark': [ChannelBulkAction.DisplayOff, DeviceBulkAction.DeviceDisplayOff],
  'btn-outline-success': [
    ChannelBulkAction.RepublishChannel,
    PlaylistBulkAction.PublishSelected,
  ],
  'btn-outline-danger': [
    ChannelBulkAction.DeleteChannel,
    PlaylistBulkAction.DeletePlaylists,
    MediaBulkAction.DeleteMedia,
  ],
  'btn-outline-warning': [
    ChannelBulkAction.AppletUpdateVersion,
    ChannelBulkAction.ClearDeviceCache,
    ChannelBulkAction.Reboot,
    DeviceBulkAction.DeviceAppletReload,
    DeviceBulkAction.DeviceAppletUpdateVersion,
    DeviceBulkAction.DeviceClearCache,
    DeviceBulkAction.DeviceReboot,
  ],
};

const getButtonClass = (
  action:
    | DeviceBulkAction
    | ChannelBulkAction
    | PlaylistBulkAction
    | MediaBulkAction
): {
  class: string;
  toolTip: string | null;
} => {
  const toolTip = `BULK_ACTION_TT_${action}`;
  switch (true) {
    // Return btn-outline-danger if action is in buttonClasses['btn-outline-danger']
    case buttonClasses['btn-outline-danger'].some((x) => x === action):
      return {
        class: 'btn-outline-danger',
        toolTip,
      };
    // Return btn-outline-success if action is in buttonClasses['btn-outline-success']
    case buttonClasses['btn-outline-success'].some((x) => x === action):
      return {
        class: 'btn-outline-success',
        toolTip,
      };
    // Return btn-outline-warning if action is in buttonClasses['btn-outline-warning']
    case buttonClasses['btn-outline-warning'].some((x) => x === action):
      return {
        class: 'btn-outline-warning',
        toolTip,
      };
    // Return btn-outline-primary if action is in buttonClasses['btn-outline-primary']
    case buttonClasses['btn-outline-primary'].some((x) => x === action):
      return {
        class: 'btn-outline-primary',
        toolTip,
      };
    // Return btn-outline-light if action is in buttonClasses['btn-outline-light']
    case buttonClasses['btn-outline-light'].some((x) => x === action):
      return {
        class: 'btn-outline-light',
        toolTip,
      };
    // Return btn-dark if action is in buttonClasses['btn-dark']
    case buttonClasses['btn-dark'].some((x) => x === action):
      return {
        class: 'btn-dark',
        toolTip,
      };
    default:
      return { class: 'btn-outline-primary', toolTip: null };
  }
};

export const bulkActionsUtil = {
  getButtonClass,
};
