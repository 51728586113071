import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { Playlist } from '@designage/gql';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import { getHierarchicalFieldValue } from '../hierarchicalFields.util';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DesignageDataTableComponent } from '../designage-data-table.component';

@Component({
  selector: 'table-generic-array',
  templateUrl: './table-generic-array.component.html',
  styleUrls: ['./table-generic-array.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgbPopoverModule,
    forwardRef(() => DesignageDataTableComponent),
  ],
})
export class TableGenericArrayComponent {
  mainColumnName = 'name';
  @Input() inputArray!: Playlist[];
  _params?: IDesignageDataTableColumns;
  @Input() set params(value: IDesignageDataTableColumns) {
    this._params = value;
    if (value.columns) {
      this.desColumns = value.columns;
      const newMain = value.columns.find((x) => x.mainColumn)?.fieldName;
      if (newMain) {
        this.mainColumnName = newMain;
      }
    }
  }

  desColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'name',
      name: 'NAME',
      mainColumn: true,
      type: 'string',
      visible: 'mandatory',
    },
  ];

  get header() {
    return this._params?.arrayHeader || '';
  }

  getMainFieldValue(row: any) {
    return getHierarchicalFieldValue(row, this.mainColumnName || 'name');
  }

  getFieldValue(row: any, fieldName: string) {
    return getHierarchicalFieldValue(row, fieldName);
  }
}
