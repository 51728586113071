import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { Device } from '@designage/gql';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import { DesignageDataTableComponent } from '../designage-data-table.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  selector: 'table-device-array',
  templateUrl: './table-device-array.component.html',
  styleUrls: ['./table-device-array.component.scss'],
  imports: [
    CommonModule,
    NgbPopoverModule,
    forwardRef(() => DesignageDataTableComponent),
  ],
})
export class TableDeviceArrayComponent implements OnInit {
  @Input('devicesArray') devicesArray!: Device[];
  desColumns: IDesignageDataTableColumns[] = [];

  ngOnInit(): void {
    this.desColumns = [
      {
        fieldName: 'id',
        name: '',
        type: 'device-status-indicator',
        visible: 'mandatory',
      },
      {
        fieldName: 'name',
        name: 'DEVICE_NAME',
        type: 'string',
        visible: 'mandatory',
      },
      {
        fieldName: 'location.name',
        name: 'LOCATION',
        type: 'object',
        visible: 'mandatory',
      },
    ];
  }
}
